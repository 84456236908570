import React, { useEffect, useRef, useState } from "react";
import Isotope from "isotope-layout";
import ProjectDetailsModal from "./ProjectDetailsModal";
const Portfolio = () => {
  // init one ref to store the future isotope object
  const isotope = useRef();
  // store the filter keyword in a state
  const [filterKey, setFilterKey] = useState("*");
  const [imagesLoaded, setimagesLoaded] = useState(0);
  const [selectedProjectDetails, setSelectedProjectDetails] = useState();
  const [isOpen, setIsOpen] = useState(false);

  const htmlElement = document.getElementsByTagName("html")[0];
  const isRtl = htmlElement.getAttribute("dir") === "rtl";

  const filters = {
    APP: "APP",
    GAME: "GAME",
    WEBSITE: "WEBSITE"
  };

  const types = {
    WEBSITE: "website",
    APP: "app",
    GAME: "game",
  };

  const projectsData = [
    {
      title: "Magnus Chess Academy",
      type: types.GAME,
      document: {
        projectInfo:
          `Learn to play and master chess in a gamified, fun and engaging way. Magnus Chess Academy is available on iOS and Android.`,
        client: "Play Magnus | Chess.com",
        technologies: "iOS, Android, Unity, node.js",
        industry: "Chess, Game, Brain Training",
        date: "2021-2023",
        url: {
          name: "www.playmagnus.com",
          link: "https://www.playmagnus.com/en/signup",
        },

        sliderImages: [
          "https://www.playmagnus.com/static/img/hero2/two-phones.png",
          "https://www.playmagnus.com/mt/become_tactic_beast_new.png",

        ],
      },

      thumbImage: "https://www.playmagnus.com/mt/the_fun_way_new.png",

      categories: [filters.APP, filters.GAME]
    },
    {
      title: "Sevensix",
      type: types.APP,
      document: {
        projectInfo:
          `The sevensix app for iOS captures your tennis swing and runs an AI-engine analysis that will automatically detect your body movement, swing curve, biomechanics, ball hit, and compare your swing to a professional player.`,
        client: "Sevensix",
        technologies: "iOS, Swift",
        industry: "Tennis, Sports Tech, App, AI",
        date: "2020-2023",
        url: {
          name: "www.sevensixtennis.com",
          link: "https://www.sevensixtennis.com",
        },

        sliderImages: [],
      },

      thumbImage: "images/sevensix.jpg",

      categories: [filters.APP],
    },
    {
      title: "9 Life-saving Rules",
      type: types.GAME,
      document: {
        projectInfo:
          `IOGP's 9 life saving rules ensure that industrial work follow safety principles. The goal of this project is to turn employee training programs into engaging virtual experiences using gamification and immersion, be it on screen devices or in Virtual Reality.`,
        client: "PaleBlue",
        technologies: "Unity",
        industry: "Gamified Training, E-learning Game, VR",
        date: "2022-2023",
        url: {
          name: "www.pale.blue",
          link: "https://pale.blue/courses/catalog/",
        },

        sliderImages: [],
      },

      thumbImage: "images/nine-life-saving.jpg",

      categories: [filters.GAME, filters.WEBSITE],
    }
  ];

  // initialize an Isotope object with configs
  useEffect(() => {
    isotope.current = new Isotope(".portfolio-filter", {
      itemSelector: ".filter-item",
      layoutMode: "masonry",
      originLeft: !isRtl,
    });

    // cleanup
    return () => {
      isotope.current.destroy();
    };
  }, []);

  // handling filter key change
  useEffect(() => {
    if (imagesLoaded) {
      filterKey === "*"
        ? isotope.current.arrange({ filter: `*` })
        : isotope.current.arrange({ filter: `.${filterKey}` });
    }
  }, [filterKey, imagesLoaded]);

  const handleFilterKeyChange = (key) => () => setFilterKey(key);

  const getKeyByValue = (value) => {
    return Object.keys(filters).find((key) => filters[key] === value);
  };

  const getFilterClasses = (categories) => {
    if (categories.length > 0) {
      let tempArray = [];
      categories.forEach((category, index) => {
        tempArray.push(getKeyByValue(category));
      });
      return tempArray.join(" ");
    }
  };

  return (
    <>
      <section id="portfolio" className={"section bg-light"}>
        <div className={"container"}>
          {/* Heading */}
          <p className="text-center mb-2 wow fadeInUp">
            <span className="bg-primary text-dark px-2">Portfolio</span>
          </p>
          <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
            Projects
          </h2>
          {/* Heading end*/}
          {/* Filter Menu */}
          <ul
            className={
              "portfolio-menu nav nav-tabs fw-600 justify-content-start justify-content-md-center border-bottom-0 mb-5 wow fadeInUp"
            }
          >
            <li className="nav-item">
              <button
                className={"nav-link " + (filterKey === "*" ? "active" : "")}
                onClick={handleFilterKeyChange("*")}
              >
                All
              </button>
            </li>
            {Object.keys(filters).map((oneKey, i) => (
              <li className="nav-item" key={i}>
                <button
                  className={
                    "nav-link " + (filterKey === oneKey ? "active" : "")
                  }
                  onClick={handleFilterKeyChange(oneKey)}
                >
                  {filters[oneKey]}
                </button>
              </li>
            ))}
          </ul>
          {/* Filter Menu end */}
          <div className="portfolio wow fadeInUp">
            <div className="row portfolio-filter filter-container g-4">
              {projectsData.length > 0 &&
                projectsData.map((project, index) => (
                  <div
                    className={
                      "col-sm-6 col-lg-4 filter-item " +
                      getFilterClasses(project.categories)
                    }
                    key={index}
                  >
                    <div className="portfolio-box">
                      <div className="portfolio-img">
                        <img
                          onLoad={() => {
                            setimagesLoaded(imagesLoaded + 1);
                          }}
                          className="img-fluid d-block portfolio-image"
                          src={project.thumbImage}
                          alt=""
                        />
                        <div
                          className="portfolio-overlay"
                          onClick={() => {
                            setSelectedProjectDetails(projectsData[index]);
                            setIsOpen(true);
                          }}
                        >
                          <button className="popup-ajax stretched-link border-0 p-0 ">
                            {" "}
                          </button>
                          <div className="portfolio-overlay-details">
                            <p className="text-primary text-8">
                              {project.type === types.GAME && (
                                <i className="fas fa-gamepad"></i>
                              )}
                              {project.type === types.APP && (
                                <i className="fas fa-mobile"></i>
                              )}
                              {project.type === types.WEBSITE && (
                                <i className="fas fa-sitemap"></i>
                              )}
                            </p>
                            <h5 className="text-white text-5">
                              {project?.title}
                            </h5>
                            <span className="text-light"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
      {/* Modal */}
      {isOpen && (
        <ProjectDetailsModal
          projectDetails={selectedProjectDetails}
          setIsOpen={setIsOpen}
        ></ProjectDetailsModal>
      )}
    </>
  );
};

export default Portfolio;
